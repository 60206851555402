<template>
  <div class="container">
    <div class="newhome">
      <div class="newhome-header">
        <img src="https://c.cdn.quyixian.com/front/2023/wen.png" alt="" />
        <div class="newhome-form">
          <div class="newhome-form-title">请输入验证码</div>
          <van-form class="newhome-form-main" @submit="onSubmit">
            <van-field
              v-if="codeShow"
              type="number"
              name="code"
              v-model="code"
              placeholder="请输入二维码下方的身份码"
              maxlength="13"
              :rules="[{ required: true }]"
            />
            <van-field
              type="number"
              name="verify"
              v-model="verify"
              placeholder="请输入验证码"
              minlength="4"
              :rules="[{ required: true }]"
            />

            <van-button
              v-if="subState"
              block
              type="info"
              icon="https://c.cdn.quyixian.com/front/click1.png"
              native-type="submit"
              >验证
            </van-button>
            <van-button
              v-else
              block
              type="info"
              loading
              loading-type="spinner"
              loading-text="验证中..."
            />
          </van-form>
        </div>
        <div class="newhome-qrcode">
          <div class="newhome-qrcode-box">
            <div class="newhome-qrcode-left">
              <img src="https://c.cdn.quyixian.com/front/ttl.jpeg" alt="" />
              <div>
                <p>5·3天天练</p>
                <p>（小学）</p>
              </div>
            </div>
            <div class="newhome-qrcode-right">
              <img src="https://c.cdn.quyixian.com/front/53tx.jpeg" alt="" />
              <div>
                <p>5·3同学</p>
                <p>（中学）</p>
              </div>
            </div>
          </div>
          <div class="newhome-qrcode-title">
            长按识别二维码关注公众号，获取更多图书增值服务
          </div>
        </div>
        <!-- <div class="newhome-prize">
          <img src="https://c.cdn.quyixian.com/front/2023/prize.png" alt="" />
        </div> -->
      </div>
      <h6 class="newhome-footer">北京曲一线图书策划有限公司</h6>
      <div id="amap-container"></div>
    </div>
    <!--  v-if="loading" <van-loading v-else class="wxinfo" type="spinner" color="#fff"
      >微信授权中...</van-loading
    > -->
  </div>
</template>

<script>
import qs from "qs";
import ax from "axios";
import { Toast, Dialog } from "vant";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      code: this.$route.query.id, //3282308232160
      codeShow: this.$route.query.id ? false : true, //是否有值，有值扫码, 无值公众号 是否显示输入框
      tid: this.$route.query.tId, //暂时无用
      verify: "", //验证码
      source: this.$route.query.source == null ? 1 : this.$route.query.source, //来源 1H5 2公众号 3手机官网 4PC官网
      bookInfo: null, //图书信息
      codeInfo: null, //码的信息
      codeType: "", //类型 1多次 2首次 3未采集或未入库出库信息 4没查到
      helpShow: false, //帮助弹出层
      subState: true,
      isWx: this.$wechat.isWeixin(), //是否再微信环境下
      province: "",
      ip: "",
      //23.9.1------
      href: window.location.origin,
      query: window.location.search,
      userinfo: sessionStorage.getItem("userinfo"),
      token: "",
      //
      map: null,
      AMapLoader: {
        key: "240e2593a45120592a96f4e0fa88509b", //WebJS-Key | keyName:codeOnlyJS
        version: "2.0",
        plugins: ["AMap.Geolocation", "AMap.Geocoder"],
      },
    };
  },
  mounted() {
    //拿IP
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.ip = ip;
        //console.log(this.ip);
      });
    //高德
    let that = this;
    AMapLoader.load(this.AMapLoader).then((AMap) => {
      this.map = new AMap.Map("amap-container");
      const Geocoder = new AMap.Geocoder();
      this.map.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
        });
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
            const {
              position: { lng, lat },
            } = result;
            Geocoder.getAddress([lng, lat], (status, res) => {
              if (status === "complete" && res.info === "OK") {
                let { addressComponent, formattedAddress } = res.regeocode;
                that.savePosition(
                  addressComponent.province,
                  addressComponent.city,
                  addressComponent.district,
                  formattedAddress,
                  lat,
                  lng
                );
                Toast(formattedAddress);
                console.log("地址:" + formattedAddress);
                console.log(addressComponent);
              } else {
                Dialog.alert({
                  title: "定位失败",
                  message: "未获取到您的位置信息！请刷新",
                  confirmButtonText: "刷新",
                }).then(() => {
                  //刷新页面
                  window.location.reload();
                });
              }
            });
          }
        });
      });
    });
    //this.getLocation();
    //是否微信环境
    // if (this.isWx) {
    //   //判断是否微信授权登录
    //   // if (this.userinfo == null) {
    //   //   let url = this.$utils.urlEncode(this.href + "/wx/" + this.query);
    //   //   window.location.replace(
    //   //     `http://c.test.quyixian.com/Home/IndexBase?reurl=${url}`
    //   //   );
    //   //   return;
    //   // } else {
    //   //   //拿用户信息
    //   //   this.userinfo = JSON.parse(this.userinfo);
    //   //   this.token = { Authorization: "Bearer " + this.userinfo.token };
    //   //   this.loading = true;
    //   // }
    //   //使用微信能力拿取用户定位信息

    //   this.$wechat.getLocation().then((res) => {
    //     this.getAddress(res.latitude, res.longitude);
    //   });
    // } else {
    //   //this.gaode();
    // }
  },
  methods: {
    //查询
    onSubmit() {
      this.subState = false;
      if (!isNaN(this.verify) && this.verify.length < 4) {
        Toast("请输入正确的验证码");
        this.subState = true;
        return false;
      }
      if (this.province == "") {
        Dialog.alert({
          title: "拒绝查询",
          message: "未获取到您的定位！",
          confirmButtonText: "刷新",
        }).then(() => {
          location.reload();
        });
        return false;
      }
      let params = {
        Code1: this.code,
        VerifyCode: this.verify,
      };
      this.$api.code.GetCode(qs.stringify(params), this.token).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          let { bookJson, codeJson, codeType } = Data;
          //取图书信息
          this.bookInfo = bookJson;
          //取类型
          this.codeType = codeType;
          //取码信息
          this.codeInfo = codeJson;

          let timestamp = new Date().getTime();

          this.$router.push({
            name: "Result",
            params: {
              code: this.code,
              verify: this.verify,
              source: this.source,
              bookInfo: this.bookInfo,
              codeType: this.codeType,
              codeInfo: this.codeInfo,
              ip: this.ip,
            },
            query: {
              time: timestamp,
              code: this.code,
            },
          });
        } else {
          this.subState = true;
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },
    //storage保存位置信息
    savePosition(pro, city, dis, add, lat, lng) {
      this.loading = true;
      this.province = pro;
      let info = {
        province: pro,
        city: city,
        district: dis,
        address: add,
        lat: lat,
        lng: lng,
      };
      sessionStorage.setItem("addr", JSON.stringify(info));
    },
    //WEBAPI获取经纬度
    // getLocation() {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       this.successCallback,
    //       this.errorCallback,
    //       {
    //         enableHighAccuracy: true,
    //         timeout: 10000,
    //         maximumAge: 0,
    //         coordsType: "wgs84",
    //         geocode: true,
    //       }
    //     );
    //   } else {
    //     console.log("浏览器不支持Geolocation API");
    //   }
    // },
    //获取经纬度成功
    // successCallback(position) {
    //   this.getadd(position.coords.longitude, position.coords.latitude);
    //   //this.getAddress(position.coords.latitude, position.coords.longitude);
    // },
    //获取经纬度失败
    // errorCallback(error) {
    //   let message = "";
    //   switch (error.code) {
    //     case error.PERMISSION_DENIED:
    //       message = "用户拒绝对获取地理位置的请求。";
    //       break;
    //     case error.POSITION_UNAVAILABLE:
    //       message = "位置信息是不可用的。";
    //       break;
    //     case error.TIMEOUT:
    //       message = "请求用户地理位置超时。";
    //       break;
    //     case error.UNKNOWN_ERROR:
    //       message = "未知错误。";
    //       break;
    //     default:
    //       message = error.mesaage;
    //   }
    // },
    //高德
    // getadd(lon, lat) {
    //   ax({
    //     method: "get",
    //     url: "https://restapi.amap.com/v3/geocode/regeo?",
    //     params: {
    //       key: "e2eec2977ea96184efe6519353d80463",
    //       location: lon + "," + lat,
    //       output: "json",
    //     },
    //   }).then((res) => {
    //     if (res.status === 200) {
    //       let { addressComponent, formatted_address } = res.data.regeocode;
    //       this.savePosition(
    //         addressComponent.province,
    //         addressComponent.city,
    //         addressComponent.district,
    //         formatted_address,
    //         lat,
    //         lon
    //       );
    //     } else {
    //       Dialog.alert({
    //         title: "定位失败",
    //         message: "未获取到您的位置信息！请刷新",
    //         confirmButtonText: "刷新",
    //       }).then(() => {
    //         //刷新页面
    //         window.location.reload();
    //       });
    //     }
    //   });
    // },
    //经纬度api换地址
    // getAddress(lat, lon) {
    //   this.$api.code
    //     .getWxAddres({
    //       location: lat + "," + lon,
    //     })
    //     .then((r) => {
    //       let { Tag, Data } = r;
    //       if (Tag == 1 && Data.message == "query ok") {
    //         let { result } = Data;
    //         console.log(result);
    //         //Toast(result.address);
    //         this.savePosition(
    //           result.address_component.province,
    //           result.address_component.city,
    //           result.address_component.district,
    //           result.address,
    //           lat,
    //           lon
    //         );
    //       } else {
    //         console.log("定位失败");
    //         Dialog.alert({
    //           title: "定位失败",
    //           message: "未获取到您的位置信息！请刷新",
    //           confirmButtonText: "刷新",
    //         }).then(() => {
    //           //刷新页面
    //           window.location.reload();
    //         });
    //       }
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
#amap-container {
  width: 100%;
  height: 1px;
  visibility: hidden;
}
.container {
  background: #48a8e7;
}
.wxinfo {
  margin-top: 0.5rem;
}
.newhome {
  background: url("https://c.cdn.quyixian.com/front/2023/bg1.png") no-repeat top
    #48a8e7;
  background-size: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &-form {
    margin: 0 0.3rem;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);
    border-radius: 0.15rem;
    display: flex;
    flex-direction: column;

    & > img {
      width: 100%;
    }

    &-title {
      width: 100%;
      height: 1.6rem;
      background: url("https://c.cdn.quyixian.com/front/2023/formhead.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0.48rem;
      letter-spacing: 1px;
    }

    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background: #fff;
      padding: 0.6rem;
      border-bottom-left-radius: 0.15rem;
      border-bottom-right-radius: 0.15rem;

      .van-cell {
        border: 1px solid #ccc;
        border-radius: 0.15rem;
        margin-bottom: 0.6rem;

        &::after {
          border-bottom: 0;
        }
      }

      .van-button {
        font-size: 0.32rem;
        &--info {
          background: linear-gradient(to bottom, #f4dc4c, #ff8350);
          border: 0;
          border-radius: 0.15rem;
        }
      }
      .van-icon__image {
        font-size: 1.2em;
      }
    }
  }

  &-qrcode {
    margin: 0.3rem;
    padding: 0.3rem 0;
    border-radius: 0.15rem;
    background: #fff;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);

    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-right,
    &-left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    &-title {
      text-align: center;
      font-weight: bold;
      font-size: 0.28rem;
    }

    img {
      max-width: 1.8rem;
      width: 1.8rem;
    }
  }

  &-prize {
    margin: 0.3rem;
    border-radius: 0.15rem;
    background: #fff;
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 45, 195, 0.66);

    img {
      width: 100%;
      height: 2.1rem;
    }
  }

  &-footer {
    color: #f1f1f1;
    text-align: center;
    font-weight: 500;
    font-size: 0.28rem;
    padding-top: 0.3rem;
    padding-bottom: 0.6rem;
  }
}
</style>
