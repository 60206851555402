<template>
  <div class="container">
    <div class="newresult">
      <template v-if="codeType == 1 || codeType == 2 || codeType == 3">
        <div class="newresult-book" v-if="bookInfo">
          <img
            class="newresult-book-title"
            src="https://c.cdn.quyixian.com/front/2023/bookinfo.jpg"
            alt=""
          />
          <div class="newresult-book-box">
            <div class="newresult-book-left">
              <img
                :src="
                  bookInfo.Cover == ''
                    ? 'https://c.cdn.quyixian.com/nocover.png'
                    : bookInfo.Cover
                "
                alt=""
              />
            </div>
            <div class="newresult-book-right">
              <div class="newresult-book-content">
                <h1 class="bookName" v-if="bookInfo.NickName">
                  {{ bookInfo.NickName }}
                </h1>
                <h1 class="title" v-else>{{ bookInfo.Name }}</h1>
                <div class="bookContent">
                  <p><span>品牌：</span><em>曲一线</em></p>
                  <p>
                    <span>版本：</span><em>{{ bookInfo.Banben }}</em>
                  </p>
                  <p>
                    <span>书号：</span><em>{{ bookInfo.BookNumber }}</em>
                  </p>
                  <p>
                    <span>开本：</span><em>{{ bookInfo.Folio }}</em>
                  </p>
                  <p>
                    <span>定价：</span
                    ><em v-if="bookInfo.Pricing">{{ bookInfo.Pricing }}元</em>
                  </p>
                  <p>
                    <span>出版社：</span><em v-html="bookInfo.Publishing"></em>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="newresult-fruit newresult-public">
          <img
            class="newresult-public-title"
            src="https://c.cdn.quyixian.com/front/2023/result.jpg"
            alt=""
          />

          <template v-if="codeType == 1 || codeCount == 0">
            <h1 class="title co">此图书首次被验证！</h1>
            <div class="content">
              <p>
                首次查询时间：{{
                  $moment(currentTime).format("YYYY年MM月DD日HH时mm分")
                }}
              </p>
              <p>首次查询地址：{{ currentAddress }}</p>
            </div>
            <!-- <router-link
              v-if="userinfo.lotteryStatus > 0"
              class="link-lucky"
              :to="{ name: 'luckDraw', query: { code: this.code } }"
              >去抽奖</router-link
            > -->
          </template>
          <template v-else>
            <template v-if="isPirate == 1">
              <h1 class="title co">
                警告：此书为盗版图书！已被验证{{ codeCount }}次！
              </h1>
              <div class="steal">
                <img
                  src="https://c.cdn.quyixian.com/front/2023/dao.png"
                  alt=""
                />
              </div>
              <div class="content" v-html="codeInfo.Info"></div>
            </template>
            <template v-else>
              <h1>
                <template v-if="codeCount >= 10">
                  <i>警告：</i>
                </template>
                此图书已被验证{{ codeCount }}次！
              </h1>
            </template>
            <div class="content">
              <p>
                首次查询时间：{{
                  $moment(firstTime).format("YYYY年MM月DD日HH时mm分")
                }}
              </p>
              <p>首次查询地址：{{ firstAddress }}</p>
              <template v-if="isPirate == 0 && codeCount >= 10">
                <p>该防伪码查询次数过多，谨防假冒！</p>
              </template>
            </div>
            <!-- <router-link
              v-if="userinfo.lotteryStatus > 0"
              class="link-lucky"
              :to="{ name: 'luckDraw', query: { code: this.code } }"
              >去抽奖</router-link
            > -->
          </template>
        </div>
      </template>
      <template v-else>
        <div class="newresult-fruit newresult-public">
          <img
            class="newresult-public-title"
            src="https://c.cdn.quyixian.com/front/2023/result.jpg"
            alt=""
          />
          <h1 class="title co">您所查询的图书可能是盗版图书</h1>
          <div class="content">
            <p>
              盗版图书可能存在危害身体健康、图书内容有误等情况，无法提供高质量的图书内容。建议您举报盗版，并通过官方渠道购买正版图书。
            </p>
          </div>
          <!-- <router-link
            class="link-lucky"
            :to="{ name: 'luckDraw', query: { code: this.code } }"
            >去抽奖</router-link
          > -->
        </div>
      </template>

      <div class="newresult-explain newresult-public">
        <img
          class="newresult-public-title"
          src="https://c.cdn.quyixian.com/front/2023/explain.jpg"
          alt=""
        />
        <div class="newresult-explain-box">
          <img src="https://c.cdn.quyixian.com/front/2023/code.png" alt="" />
          <h6>喷印工艺</h6>
          <p>
            正版图书的验证二 维码会采用喷印工 艺，用手触摸二维 码会有凹凸不平的
            感觉。
          </p>
        </div>
        <div class="newresult-explain-box newresult-explain-line">
          <img src="https://c.cdn.quyixian.com/front/2023/blue.png" alt="" />
          <h6>隐藏防伪</h6>
          <p>
            使用激光笔照射正版图书封面或书脊，会显示和封底二维码下方的身份码相同的数字。
          </p>
        </div>
        <div class="newresult-explain-box">
          <img src="https://c.cdn.quyixian.com/front/2023/one.png" alt="" />
          <h6>一书一码</h6>
          <p>
            正版图书的验证码 为一书一码，且每 本图书均有唯一的 13位身份码及4位
            验证码。
          </p>
        </div>
      </div>
      <router-link class="newresult-steal" :to="'/pirate?code=' + code" replace>
        <img src="https://c.cdn.quyixian.com/front/2023/steal2.png" alt="" />
      </router-link>

      <div class="newresult-vs">
        <router-link :to="'/cont'"
          ><img src="https://c.cdn.quyixian.com/front/2023/banner.png" alt=""
        /></router-link>
      </div>
    </div>
    <div class="qyx">北京曲一线图书策划有限公司</div>
  </div>
</template>

<script>
import qs from "qs";
import { Toast, Dialog } from "vant";
export default {
  name: "Result",
  data() {
    return {
      loading: false,
      code: this.$route.query.code, //码
      verify: this.$route.params.verify, //验证码
      source: this.$route.params.source, //来源
      bookInfo: this.$route.params.bookInfo, //图书信息
      codeInfo: this.$route.params.codeInfo, //码信息
      codeType:
        this.$route.params.codeType == null ? 4 : this.$route.params.codeType, //类型  1多次 2首次 3未采集或未入库出库信息  4没查到
      ip: this.$route.params.ip,
      map: JSON.parse(sessionStorage.getItem("addr")), //定位信息
      //sohu: returnCitySN, //搜狐定位
      isPirate: 0, //是否提示盗版信息来源后台 默认0关闭
      codeCount: 0, //查询次数
      firstTime: "", //首次时间
      firstAddress: "", //首次地址
      currentAddress: "", //当前地址
      currentTime: new Date(), //当前时间

      // userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      // token: "",
    };
  },
  mounted() {
    if (this.ip == null || this.ip == "") {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    }
    // this.token = { Authorization: "Bearer " + this.userinfo.token };

    if (this.codeInfo != null) {
      let { FirstTime, FirstAddress, Count, IsPirate, Info } = this.codeInfo;
      this.isPirate = IsPirate;
      this.firstTime = FirstTime;
      this.firstAddress = FirstAddress;
      if (Count && Count > 0) {
        this.codeCount = Count + 1;
      }
    }
    if (this.map && this.map.province != null) {
      this.currentAddress =
        this.map.address == null ? this.map.province : this.map.address;
      //console.log(this.currentAddress);
    }

    if (this.bookInfo != null) {
      let pub = this.bookInfo.Publishing;
      if (pub) {
        this.bookInfo.Publishing = pub.replace(/  /, "<br/>");
      }
    }

    //延时保存记录;
    let that = this;
    setTimeout(function () {
      if (that.map && (that.map.address == null || that.map.province == null)) {
        Dialog.alert({
          title: "拒绝查询",
          message: "获取位置信息异常！",
          confirmButtonText: "返回",
        }).then(() => {
          that.$router.push({ path: "/", query: { id: that.code } });
        });
        return false;
      } else {
        that.SaveUser();
      }
    }, 1000);
  },
  methods: {
    SaveUser() {
      let task = null,
        taskpress = null,
        book = null,
        code2 = null;
      if (this.codeInfo != null) {
        if (this.codeInfo.TaskId) {
          task = this.codeInfo.TaskId;
        }
        if (this.codeInfo.TaskId) {
          taskpress = this.codeInfo.TaskPressId;
        }
        if (this.codeInfo.BookId) {
          book = this.codeInfo.BookId;
        }
        if (this.codeInfo.Code2) {
          code2 = this.codeInfo.Code2;
        }
      }
      let info = "";
      if (this.map && this.map.province != null) {
        info =
          "province:" +
          this.map.province +
          ",city:" +
          this.map.city +
          ",district:" +
          this.map.district +
          ",lat:" +
          this.map.lat +
          ",lng:" +
          this.map.lng;
      }
      let params = {
        CodeType: this.codeType,
        Code1: this.code,
        VerifyCode: this.verify,
        TaskId: task,
        TaskPressId: taskpress,
        BookId: book,
        Code2: code2,
        Source: this.source,
        //
        IP: this.ip,
        Address: this.map != null ? this.map.address : "",
        Province: this.map != null ? this.map.province : "",
        City: this.map != null ? this.map.city : "",
        District: this.map != null ? this.map.district : "",
        Info: info,
      };
      this.$api.code
        .SaveUserInfo(qs.stringify(params), this.token)
        .then((res) => {
          let { Data, Tag, Message } = res;
          if (Tag == 1) {
            //console.log(params);
          } else {
            this.$dialog.alert({
              title: "错误",
              message: Message,
            });
          }
        });
    },

    formatStr(str) {
      str = str.replace(/\r\n/gi, "<br/>");
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.newresult {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 0 0.3rem;
  &-public {
    border: 1px solid #5795e0;
    border-radius: 0.3rem;
    position: relative;
    margin: 0.6rem 0;

    &-title {
      position: absolute;
      left: 50%;
      top: -0.37rem;
      width: 2.94rem;
      height: 0.74rem;
      transform: translateX(-50%);
    }
  }
  &-book {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.3rem 0;

    &-title {
      width: 2.94rem;
      height: 0.74rem;
      margin-bottom: 0.3rem;
    }
    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-left {
      max-width: 2.7rem;
      max-height: 4.02rem;
      img {
        width: 100%;
      }
    }
    &-right {
      flex: 1;
      width: 4.34rem;
      height: 4.28rem;
      background: url("https://c.cdn.quyixian.com/front/2023/bookinfo0bg1.png")
        no-repeat;
      background-size: 100%, 100%;
    }

    &-content {
      margin: 0.3rem;
      padding-left: 0.1rem;

      .bookName {
        font-size: 0.28rem;
        font-weight: 500;
        line-height: 1.6;
        margin: 0.15rem 0;
      }
      .bookContent {
        font-size: 0.2rem;

        p {
          line-height: 1.8;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        span {
          width: 1rem;
          text-align: right;
        }
      }
    }
  }
  &-fruit {
    width: 90%;
    padding: 0.6rem 0.3rem;

    & > h1 {
      font-size: 0.46rem;
    }
    .title {
      text-align: center;
      font-size: 0.4rem;
      margin: 0.15rem;
    }
    .content {
      font-size: 0.24rem;
      line-height: 2.4;
    }
    .co {
      color: #ff8450;
    }
    .steal {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem 0;
      width: 100%;

      img {
        width: 2.68rem;
        height: 2.58rem;
      }
    }
    .link-lucky {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #f4dc4c, #ff8350);
      color: #fff;
      border-radius: 0.15rem;
      height: 0.8rem;
      margin-top: 0.3rem;
    }
  }
  &-steal {
    display: flex;
    margin-bottom: 0.3rem;
    box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.1);
    img {
      width: 100%;
    }
  }
  &-explain {
    display: flex;
    justify-content: space-around;
    padding: 0.6rem 0;
    align-items: flex-start;

    &-line {
      border-right: 2px dashed #5795e0;
      border-left: 2px dashed #5795e0;
    }

    &-box {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 0.15rem;

      h6 {
        color: #ff8450;
        font-size: 0.28rem;
      }

      img {
        width: 1.3rem;
        height: 1.3rem;
      }
      p {
        font-size: 0.24rem;
        line-height: 2;
      }
    }
  }
  &-vs {
    margin: 0.3rem 0;

    img {
      width: 100%;
    }
  }
}
.qyx {
  text-align: center;
  font-size: 0.28rem;
  color: #999;
  padding: 0.3rem;
}
</style>
