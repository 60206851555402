import Vue from "vue";
import VueRouter from "vue-router";
//
import Pirate from "../views/Pirate";
import PirateForm from "../views/PirateForm";
import cont from "../views/cont";
//
import newhome from "../views/2023/newHome";
import newresult from "../views/2023/newresult";
import luckDraw from "../views/2023/luckDraw";
import success from "../views/2023/success";
import address from "../views/2023/address";
import wx from "../views/2023/wx";
import amap from "../views/2023/amapDemo";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: newhome,
  },
  {
    path: "/result",
    name: "Result",
    component: newresult,
  },
  {
    path: "/PirateForm",
    name: "PirateForm",
    component: PirateForm,
  },
  {
    path: "/pirate",
    name: "Pirate",
    component: Pirate,
  },
  {
    path: "/cont",
    name: "cont",
    component: cont,
  },
  {
    path: "/luck",
    name: "luckDraw",
    component: luckDraw,
  },
  {
    path: "/wx",
    name: "wx",
    component: wx,
  },
  {
    path: "/success",
    name: "success",
    component: success,
  },
  {
    path: "/address",
    name: "address",
    component: address,
  },
  {
    path: "/amap",
    name: "amap",
    component: amap,
  },
  {
    path: "/anticheck/index",
    name: "index",
    component: () => import("@/views/2023overlay/index.vue"),
  },
  {
    path: "/anticheck/detail",
    name: "detail",
    component: () => import("@/views/2023overlay/detail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(tp, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
