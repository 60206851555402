<template>
  <div class="luck">
    <van-image
      class="luck-title"
      fit="contain"
      src="https://c.cdn.quyixian.com/front/prize/title.png"
    />
    <div class="luck-box">
      <LuckyWheel
        class="luck-box-turn"
        ref="myLucky"
        width="365px"
        height="365px"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        :defaultConfig="defaultConfig"
        @start="startCallback"
        @end="endCallback"
      />
    </div>
    <div class="luck-func">
      <span
        class="luck-func-btn"
        @click="
          {
            recordShow = true;
          }
        "
        >中奖记录</span
      >
      <span class="luck-func-btn" @click="nointe">积分兑换</span>
    </div>
    <div class="luck-content">
      <h5 class="luck-content-title">活动规则</h5>
      <p>1、首次验证，只可参与1次抽奖；</p>
      <p>2、红包即中即发，实物奖品按自然月统一发放；</p>
      <p>3、积分奖励可在“5·3天天练”公众号使用；</p>
      <p>4、因网络或手机设置导致无法收取红包得视为放弃；</p>
      <p>
        5、中奖用户请填写正确物流信息，因地址有误导致奖品不能正常发放的，视为自动放弃；
      </p>
      <p>6、活动解释权归曲一线公司所有。</p>
    </div>
    <van-popup
      :class="
        prizeNum < 6 ? 'luck-prizePopup luck-prizePopup1' : 'luck-prizePopup'
      "
      v-model="prizeShow"
      closeable
      close-icon="close"
    >
      <div
        :class="
          prizeNum < 6
            ? 'luck-prizePopup-info luck-prizePopup1-info'
            : 'luck-prizePopup-info'
        "
      >
        <p class="text">{{ prizeName }}</p>
      </div>
    </van-popup>
    <van-popup
      class="luck-recordPopup"
      v-model="recordShow"
      position="bottom"
      close-icon="close"
      closeable
      round
      :style="{ height: '50%' }"
    >
      <h6 class="title">中奖记录</h6>
      <div class="list">
        <p v-for="item in prizeList" :key="item.id">
          {{ $moment(item.addTime).format("YYYY年MM月DD日HH时mm分") }}
          获得：{{ item.prizeName.name }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
import { Toast, Dialog } from "vant";
export default {
  name: "luckDraw",
  data() {
    return {
      rid: "",
      code: this.$route.query.code,
      href: window.location.hostname,
      userinfo: sessionStorage.getItem("userinfo"),
      token: "",
      //
      prizeShow: false,
      recordShow: false,
      //
      blocks: [
        {
          imgs: [
            {
              src: "https://c.cdn.quyixian.com/front/prize/prize-bg3.png",
              top: "0",
              width: "100%",
              height: "100%",
              rotate: true,
            },
          ],
        },
      ],
      prizes: [
        {
          fonts: [{ text: "0", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(255,255,255,0.7)",
        }, //0-53图书-2
        {
          fonts: [{ text: "1", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(200,200,200,0.5)",
        }, //1-现金红包-4
        {
          fonts: [{ text: "2", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(150,150,150,0.9)",
        }, //2-53笔袋-3
        {
          fonts: [{ text: "3", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(100,100,100,0.8)",
        }, //3-谢谢参与-6
        {
          fonts: [{ text: "4", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(150,150,150,0.6)",
        }, //4-积分-5
        {
          fonts: [{ text: "5", top: "0", fontColor: "transparent" }],
          background: "transparent",
          //background: "rgba(50,50,50,0.5)",
        }, //5-53书包-1
      ],
      buttons: [
        {
          radius: "30%",
          imgs: [
            {
              src: "https://c.cdn.quyixian.com/front/prize/prize-btn.png",
              width: "100%",
              top: "-125%",
            },
          ],
          fonts: [
            {
              text: "剩余1次",
              fontSize: ".24rem",
              fontColor: "#fff",
              top: "30%",
            },
          ],
        },
      ],
      defaultConfig: {
        offsetDegree: -30,
        stopRange: 0.5,
      },
      //
      prizeNum: 6, //当前中奖号
      prizeName: "谢谢参与", //当前中奖名称
      prizeList: [], //中奖列表
      prizeNormal: true, //开始抽奖后是否正常获取抽奖结果  true获取  false不获取

      //
      models: null,
      timer: null,
    };
  },
  mounted() {
    let that = this;
    this.userinfo = JSON.parse(this.userinfo);
    this.token = { Authorization: "Bearer " + this.userinfo.token };
    //延迟查询用户抽奖数据
    setTimeout(() => {
      that.getCJList();
    }, 1000);
  },
  methods: {
    startCallback() {
      //中奖索引是3-谢谢参与
      const that = this;
      let index = 3;
      //调用开始抽奖接口获取ID
      //调用抽奖组件的play方法开始
      this.$refs.myLucky.play();
      this.$api.code
        .saveLotterycj(
          qs.stringify({
            code1: this.code,
          }),
          this.token
        )
        .then((res) => {
          let { Tag, Data, Message } = res;
          if (Tag == 1) {
            this.rid = Data;
          } else {
            //停止转盘 弹出提示
            Dialog.alert({
              message: Message,
            }).then(() => {
              this.$refs.myLucky.stop(index);
            });
            this.prizeNormal = false; //跳过获取抽奖结果数据
          }
        });

      setTimeout(() => {
        //循环定时异步访问接口
        if (that.prizeNormal) {
          this.timer = window.setInterval(() => {
            if (that.models) {
              //清理定时器
              clearInterval(that.timer);
              that.timer = null;
              //处理结果文本 中奖结果
              let result = that.retText(
                that.models.prizeType,
                that.models.price
              );
              that.prizeName = result.name;
              that.prizeNum = that.models.prizeType;
              index = result.number;
              //中奖记录追加 unshift
              this.prizeList.unshift({
                id: that.models.id,
                addTime: that.models.addTime,
                prizeName: result,
              });
              that.$refs.myLucky.stop(index);
              //console.log(that.models);
            } else {
              //处理请求数据
              this.getResult().then((res) => {
                that.models = res.Data;
              });
              console.log(that.models);
            }
          }, 2000);
        } else {
          that.$refs.myLucky.stop(index);
        }
      }, 5000);
      that.buttons[0].fonts[0].text = "剩余0次";
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      if (this.prizeNormal) {
        setTimeout(() => {
          this.prizeShow = true;
        }, 1000);
      }
      //console.log(prize);
    },

    //获取抽奖结果
    getResult() {
      return new Promise((resolve, reject) => {
        this.$api.code
          .getLotteryResult(qs.stringify({ recordId: this.rid }), this.token)
          .then((res) => {
            //this.models = res.Data; //备用
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    nointe() {
      Toast("暂未开通");
    },
    //获取中奖记录
    getCJList() {
      this.$api.code
        .getLotteryList(qs.stringify({}), this.token)
        .then((res) => {
          let { Tag, Data, Message } = res;
          if (Tag === 1) {
            for (let i = 0; i < Data.length; i++) {
              Data[i].prizeName = this.retText(
                Data[i].prizeType,
                Data[i].price
              );
            }
            this.prizeList = Data;
          } else {
            Dialog.alert({
              title: "获取失败",
              message: Message,
            });
          }
        });
    },
    //处理弹出中奖文本信息
    retText(prizeNum, price) {
      let prizeName = "谢谢参与";
      let pn = 3;
      switch (prizeNum) {
        case 1:
          prizeName = "5·3书包";
          pn = 5;
          break;
        case 2:
          prizeName = "5·3图书";
          pn = 0;
          break;
        case 3:
          prizeName = "5·3笔袋";
          pn = 2;
          break;
        case 4:
          prizeName =
            "现金红包" +
            (price == undefined || price == null ? 0 : price) +
            "元";
          pn = 1;
          break;
        case 5:
          prizeName =
            (price == undefined || price == null ? 0 : price) + "积分";
          pn = 4;
          break;
        default:
          prizeName = "谢谢参与";
          pn = 3;
      }
      return { name: prizeName, number: pn };
    },
  },
};
</script>
<style lang="scss">
.luck {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ff1d4a url("https://c.cdn.quyixian.com/front/prize/bg.png")
    no-repeat top;
  background-size: 100% auto;

  &-title {
    padding-top: 0.5rem;
    width: 80%;
  }

  &-box {
    width: 100%;
    min-height: 9.24rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: url("https://c.cdn.quyixian.com/front/prize/turn-bg.png")
      no-repeat center;
    background-size: 100% 100%;

    &-turn {
      padding-top: 0.6rem;
    }
  }

  &-func {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-top: 0.3rem;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffe4b7;
      color: #f76250;
      font-size: 0.32rem;
      width: 40%;
      border: 2px solid #fff;
      border-radius: 0.15rem;
    }
  }

  &-content {
    background: #ffe4b7;
    border: 2px solid #fff;
    border-radius: 0.15rem;
    margin: 0.3rem 0.3rem 0.9rem 0.3rem;
    padding: 0.15rem 0.3rem;

    &-title {
      font-size: 0.28rem;
      font-weight: 400;
    }
    p {
      font-size: 0.24rem;
      line-height: 2.2;
    }
  }

  &-prizePopup {
    background: url("https://c.cdn.quyixian.com/front/prize/npp.png") no-repeat
      center;
    background-size: 100% 100%;
    width: 80%;
    min-height: calc(8.4rem * 0.8);
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .van-icon {
      font-size: 0.5rem;
      font-weight: bold;
      color: #999;
    }

    &-info {
      padding-top: 2.4rem;

      .text {
        font-size: 0.6rem;
        letter-spacing: 2px;
        color: #ffe4b7;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &-prizePopup1 {
    background: url("https://c.cdn.quyixian.com/front/prize/pp.png") no-repeat
      center;
    background-size: 100% 100%;

    &-info {
      padding-top: 2.8rem;
    }
  }

  &-recordPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .van-icon {
      color: #999;
    }
    .title {
      font-size: 0.36rem;
      font-weight: 400;
      color: #ff1d4a;
      padding-top: 0.3rem;
      padding-bottom: 0.15rem;
    }
    .list {
      flex: 1;
      width: 90%;
      font-size: 0.24rem;
      margin-bottom: 0.8rem;
      overflow-y: auto;

      p {
        margin: 10px 0;
        line-height: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .exp {
        display: inline-block;
        background: #ffe4b7;
        color: #ff1d4a;
        padding: 0 10px;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
  }
}
</style>
