<template>
  <div>
    <div id="amap-container"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map: null,
      //地图加载参数
      AMapLoader: {
        key: "240e2593a45120592a96f4e0fa88509b", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geolocation", "AMap.Geocoder"], // 需要使用的插件列表，如比例尺'AMap.Scale'等
      },
      //地图绘制参数
      //   parameter: {
      //     zoom: 10, //级别
      //     viewMode: "2D", //使用3D视图
      //     resizeEnable: true,
      //     enableHighAccuracy: true, //是否使用高精度定位，默认:true
      //     convert: true,
      //   },
    };
  },

  methods: {
    // 根据获取到的经纬度进行逆地理编码
    /*
      real:逆向转码实例 就是 Geocoder
      lng和lat就是经纬度
      */
    // reverse(real, lng, lat) {
    //   real.getAddress([lng, lat], (status, res) => {
    //     if (status === "complete") {
    //       // address即经纬度转换后的地点名称
    //       console.log(res, status);
    //     }
    //   });
    // },
    // 根据获取到的经纬度进行正向地理编码
    /*
      real:逆向转码实例 就是 Geocoder
      lng和lat就是具体地理位置
      */
    // limit(real, address) {
    //   real.getLocation(address, (res, req) => {
    //     console.log(res, req);
    //   });
    // },
  },

  mounted() {
    let that = this;
    AMapLoader.load(this.AMapLoader).then((AMap) => {
      //实例地图
      this.map = new AMap.Map("amap-container");
      //实例逆向转码
      const Geocoder = new AMap.Geocoder();
      //   this.map.on("click", function (e) {
      //     //根据获取到的经纬度进行逆地理编码
      //     that.reverse(Geocoder, e.lnglat.getLng(), e.lnglat.getLat());
      //     //根据获取到的经纬度进行正向地理编码
      //     //that.limit(Geocoder, "北京市朝阳区阜荣街10号");
      //   });

      this.map.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：无穷大
          // maximumAge: 0, //定位结果缓存0毫秒，默认：0
          //   convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          //   showButton: true, //显示定位按钮，默认：true
          //   buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          //   showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          //   showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          //   panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          //   zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
            const {
              position: { lng, lat },
            } = result;
            Geocoder.getAddress([lng, lat], (status, res) => {
              if (status === "complete" && res.info === "OK") {
                let { addressComponent, formattedAddress } = res.regeocode;
                // console.log(addressComponent);
                // addressComponent.province
                // addressComponent.city
                // addressComponent.district
                console.log(formattedAddress);
              }
            });

            // 根据获取到的经纬度进行逆地理编码
            //that.reverse(Geocoder, lng, lat);
          }
        });
      });
    });
  },
};
</script>

<style>
#amap-container {
  width: 100%;
  height: 1px;
  visibility: hidden;
}
</style>
