<template>
  <div class="pirate-box">
    <h1>举报盗版</h1>
    <van-form class="pirate-form" @submit="onSubmit">
      <div class="pirate-form-photo">
        <p class="minTitle">请上传图书封面截图<em class="red">（必填）</em></p>
        <div class="imgFun">
          <van-uploader
            v-model="photo1"
            :max-count="1"
            accept="image/*"
            :after-read="afterRead1"
          />
          <div class="example">
            <img
              src="https://c.cdn.quyixian.com/front/pirate/book2.png"
              alt=""
            />
            <span>︵示例︶</span>
          </div>
        </div>
      </div>
      <div class="pirate-form-photo">
        <p class="minTitle">请上传图书封底截图<em class="red">（必填）</em></p>
        <div class="imgFun">
          <van-uploader
            v-model="photo2"
            :max-count="1"
            accept="image/*"
            :after-read="afterRead2"
          />

          <div class="example">
            <img
              src="https://c.cdn.quyixian.com/front/pirate/book1.png"
              alt=""
            />
            <span>︵示例︶</span>
          </div>
        </div>
      </div>
      <div class="pirate-form-photo pirate-form-photo-noborder">
        <p class="minTitle">请上传购买店铺相关信息</p>
        <div class="minText">
          <van-field
            label="书店名称："
            v-model="bookName"
            placeholder="请详细填写书店名称"
          />
        </div>
        <div class="minText">
          <van-field
            label="书店地址："
            v-model="bookAddress"
            placeholder="请填写书店详细地址或书店网址"
          />
        </div>
      </div>
      <div class="pirate-form-photo">
        <p class="minTitle">如在网店购买可上传店铺信息截图</p>
        <div class="imgFun">
          <van-uploader
            v-model="photo3"
            :max-count="1"
            accept="image/*"
            :after-read="afterRead3"
          />
        </div>
      </div>
      <div class="pirate-form-photo">
        <p class="minTitle">请上传与商家协商相关信息截图或其他相关线索截图</p>
        <div class="imgFun">
          <van-uploader
            v-model="photo4"
            multiple
            :max-count="4"
            accept="image/*"
            :after-read="afterRead4"
          />
        </div>
      </div>
      <div class="pirate-form-photo pirate-form-photo-noborder">
        <div class="minText">
          <van-field
            label="联系方式："
            v-model="userPhone"
            placeholder="请填写本人联系电话"
            :rules="[{ validator: IsPhone, message: '请输入正确的手机号' }]"
          />
        </div>
      </div>
      <div class="pirate-form-photo pirate-form-photo-noborder message">
        <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="如有其他问题，请给我们留言"
          show-word-limit
        />
      </div>
      <van-button
        native-type="submit"
        icon="https://c.cdn.quyixian.com/front/pirate/submit.png"
      >
      </van-button>
    </van-form>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import qs from "qs";
import { imgPreview } from "@/assets/scripts/compress";

export default {
  name: "PirateForm",
  data() {
    return {
      code: this.$route.query.code,
      bookcode: this.$route.query.bookcode, //反馈平台小程序传入  图书编码
      bookname: this.$route.query.bookname, //反馈平台小程序传入  图书名称
      photo1: [], //默认
      photo2: [], //默认
      photo3: [], //默认
      photo4: [], //默认
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      bookName: "",
      bookAddress: "",
      userPhone: "",
      message: "",
      userinfo: sessionStorage.getItem("userinfo"),
      token: "",
    };
  },
  mounted() {
    this.userinfo = JSON.parse(this.userinfo);
    this.token = { Authorization: "Bearer " + this.userinfo.token };
  },
  methods: {
    //form
    onSubmit() {
      if (this.img1.length == 0) {
        Toast("请上传图书封面照片");
        return false;
      }
      if (this.img2.length == 0) {
        Toast("请上传图书封底照片");
        return false;
      }
      let params = {
        code: this.code,
        bookcode: this.bookcode,
        bookname: this.bookname,
        img1: this.img1,
        img2: this.img2,
        shopName: this.bookName,
        shopAddress: this.bookAddress,
        img3: this.img3,
        img4: this.img4,
        remark: this.message,
        mobile: this.userPhone,
      };
      this.$api.code
        .SaveUserPirate(qs.stringify(params), this.token)
        .then((res) => {
          let { Data, Tag, Message } = res;
          if (Tag == 1) {
            Dialog.alert({
              title: "保存成功",
              message: "感谢您的举报盗版信息！",
            }).then(() => {
              this.$router.replace({
                name: "Pirate",
              });
            });
          }
        });
    },
    //手机正则
    IsPhone(v) {
      return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(v);
    },
    //保存图片
    afterRead1(file) {
      this.NewSaveFile(file, 1);
    },
    afterRead2(file) {
      this.NewSaveFile(file, 2);
    },
    afterRead3(file) {
      this.NewSaveFile(file, 3);
    },
    afterRead4(file) {
      this.NewSaveFile(file, 4);
    },
    NewSaveFile(file, num) {
      let that = this;
      if (file instanceof Array) {
        for (let i = 0; i < file.length; i++) {
          (function (arg) {
            let formData = new FormData();
            imgPreview(file[arg].file, async (files) => {
              formData.append("fileList", files);
              await that.$api.code.saveFileOSS(formData).then((res) => {
                let { Data, Tag, Message } = res;
                if (Tag == 1) {
                  Toast.success("保存成功");
                  //赋值
                  if (num == 1) {
                    that.img1 = Data;
                  } else if (num == 2) {
                    that.img2 = Data;
                  } else if (num == 3) {
                    that.img3 = Data;
                  } else if (num == 4) {
                    if (that.img4) {
                      that.img4 = that.img4 + "," + Data;
                    } else {
                      that.img4 = Data;
                    }
                  }
                } else {
                  Dialog.alert({
                    title: "保存失败",
                    message: Message,
                  });
                }
              });
            });
          })(i);
        }
      } else {
        let formData = new FormData();
        imgPreview(file.file, (files) => {
          formData.append("fileList", files);
          this.$api.code.saveFileOSS(formData).then((res) => {
            let { Data, Tag, Message } = res;
            if (Tag == 1) {
              Toast.success("保存成功");
              //赋值
              if (num == 1) {
                this.img1 = Data;
              } else if (num == 2) {
                this.img2 = Data;
              } else if (num == 3) {
                this.img3 = Data;
              }
            } else {
              Dialog.alert({
                title: "保存失败",
                message: Message,
              });
            }
          });
        });
      }
    },
    SaveFile(file, num) {
      let File = [];
      const formData = new FormData();
      if (Array.isArray(file)) {
        File = file;
      } else {
        File.push(file);
      }
      File.map((item) => {
        formData.append("fileList", item.file, item.file.name);
      });
      this.$api.code.saveFileOSS(formData).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          Toast.success("保存成功");
          //赋值
          if (num == 1) {
            this.img1 = Data;
          } else if (num == 2) {
            this.img2 = Data;
          } else if (num == 3) {
            this.img3 = Data;
          } else if (num == 4) {
            if (this.img4) {
              this.img4 = this.img4 + "," + Data;
            } else {
              this.img4 = Data;
            }
          }
        } else {
          Dialog.alert({
            title: "保存失败",
            message: Message,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.van-uploader__preview {
  margin: 0 5px 0 0 !important;
}
.van-field__label {
  color: #333 !important;
  // width: 5em !important;
}
.pirate-box {
  h1 {
    font-size: 0.36rem;
    font-weight: normal;
    text-align: center;
    line-height: 2.8;
    border-bottom: 1px solid #e2e2e2;
    box-shadow: 0 0 5px #e2e2e2;
  }
}

.pirate-form {
  .message {
    .van-field {
      background: #f5f5f5;
    }
  }

  &-photo {
    font-size: 0.28rem;
    margin: 0 0.3rem;
    padding: 0.1rem 0;
    border-bottom: 1px solid #cccccc;

    &-noborder {
      border-bottom-width: 0 !important;
      padding-top: 0.3rem;
    }

    .minTitle1 {
      padding: 0.1rem 0;
    }

    .minText {
      .van-cell {
        padding: 8px !important;
      }

      .van-field {
        flex: 1;
        border-bottom: 1px solid #ccc;
        margin-bottom: 0.2rem;
      }
    }

    .red {
      color: #e35241;
    }

    .imgFun {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .van-uploader {
        margin-top: 7px;
      }

      .example {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 1.24rem;
        }

        span {
          writing-mode: vertical-lr;
          margin-left: 0.1rem;
          color: #e35241;
        }
      }
    }
  }

  .van-button {
    margin: 0.3rem 0;

    &--normal {
      padding: 0 !important;
      font-size: 0.28rem !important;
    }

    &--default {
      border: 0 !important;
    }

    &__icon {
      line-height: normal !important;
    }

    .van-icon {
      &__image {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
