<template>
  <div class="address">
    <div class="address-title">填写收货信息</div>
    <van-form @submit="onSubmit" class="address-content">
      <div class="address-top">
        <img
          class="address-book"
          src="https://c.cdn.quyixian.com/front/2023/book.png"
          alt=""
        />
        <div class="address-box">
          <van-field
            v-model="tname"
            type="text"
            label="收货人"
            required
            placeholder="请填写收货人姓名"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="address-box">
          <van-field
            v-model="tphone"
            type="tel"
            label="手机号码"
            required
            placeholder="请填写收货人手机号码"
            :rules="[{ required: true }]"
          />
        </div>
        <div class="address-box">
          <van-field
            readonly
            clickable
            required
            name="area"
            :value="area"
            label="所在地区"
            placeholder="点击选择省市区县、乡镇等"
            :rules="[{ required: true }]"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onAreaConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
        </div>
        <div class="address-box">
          <van-field
            v-model="address"
            required
            name="address"
            label="详细地址"
            placeholder="街道、楼牌号等"
            :rules="[{ required: true }]"
          />
        </div>
      </div>
      <div class="address-bottom">
        <van-button type="primary" block round>确定</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import areaList from "@/assets/scripts/area.js";
export default {
  name: "addres",
  data() {
    return {
      map: JSON.parse(sessionStorage.getItem("addr")), //定位信息
      rid: this.$route.query.rid, //中奖ID
      code: this.$route.query.code, //防伪码
      ptype: this.$route.query.ptype, //几等奖
      tname: "",
      tphone: "",
      area: "",
      showArea: false,
      areaList,
      province: "",
      city: "",
      district: "",
      address: "",
    };
  },
  mounted() {
    if (this.map != null) {
      this.area =
        this.map.province + "-" + this.map.city + "-" + this.map.district;
      this.province = this.map.province;
      this.city = this.map.city;
      this.district = this.map.district;
      this.address = this.map.address;
    }
  },
  methods: {
    onSubmit() {
      // 提交表单
      if (this.tname == "") {
        Toast("请输入收件人姓名");
        return;
      }
      if (this.tphone == "") {
        Toast("请输入收件人手机号");
        return;
      }
      if (this.address == "") {
        Toast("请输入详细地址");
        return;
      }
      if (this.area == "") {
        Toast("请选择所在地区");
        return;
      }
      let params = {
        PrizeRecordId: this.rid,
        PrizeType: this.ptype,
        Province: this.province,
        City: this.city,
        Area: this.district,
        Address: this.address,
        Name: this.tname,
        Phone: this.tphone,
        Code: this.code,
        Grade: "",
        Subject: "",
        Location: "",
      };
      this.$api.code.saveAddress(params).then((res) => {
        let { tag, data } = res;
      });
    },
    onAreaConfirm(values) {
      this.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("-");
      this.showArea = false;
      this.province = values[0].name;
      this.city = values[1].name;
      this.district = values[2].name;
    },
    validator(v) {
      return /^1\d{10}$/.test(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  height: 100%;
  background: #5795e0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &-title {
    color: #fff;
    font-size: 0.48rem;
    text-align: left;
    margin: 0.6rem 0.3rem 0.3rem 0.3rem;
  }
  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.3rem;
    background: #fff;
    margin: 0.3rem;
    padding: 0.3rem;
    font-size: 0.28rem;
    position: relative;
  }

  &-book {
    position: absolute;
    right: 0.3rem;
    top: -1.46rem;
    width: 2.1rem;
    height: 1.46rem;
  }

  &-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    padding: 0.3rem 0;
    border-bottom: 1px solid #ececec;

    p {
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 1.6;
    }
  }

  .van-button {
    &--primary {
      background: linear-gradient(to bottom, #f4dc4c, #ff8350);
      border: 0;
    }
  }
}
</style>
