<template>
  <div class="container wx">
    <van-loading class="wxinfo" type="spinner" color="#fff"
      >微信授权中...</van-loading
    >
  </div>
</template>

<script>
import qs from "qs";
import { Dialog } from "vant";
export default {
  name: "wx",
  data() {
    return {
      qCode: this.$route.query.code,
      qState: 1,
      url: window.location.origin + window.location.search,
    };
  },
  mounted() {
    this.getWxLogin();
  },
  methods: {
    getWxLogin() {
      let params = {
        code: this.qCode,
        state: this.qState,
      };
      this.$api.code.getWxLogin(qs.stringify(params)).then((res) => {
        let { Tag, Data, Message } = res;
        if (Tag == 1) {
          sessionStorage.setItem("userinfo", JSON.stringify(Data));
          window.location.replace(this.url);
        } else {
          Dialog.alert({
            title: "微信授权失败",
            message: Message,
          }).then(() => {
            window.location.replace(this.url);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #48a8e7;
}
.wxinfo {
  margin-top: 1rem;
}
</style>
